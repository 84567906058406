import { VehiclePosLogWindow } from "domain/vehiclePosLog/VehiclePosLogWindow";
import { WindowStaticProps } from "..";
import { ItcDetailsWindow } from "../../../domain/itc/details/ItcDetailsWindow";
import { ItcListWindow } from "../../../domain/itc/ItcListWindow";
import { ItcStateStatsWindow } from "../../../domain/itc/stats/ItcStateStatsWindow";
import { MapWindow } from "../../../domain/map/MapWindow";
import { IconLegendWindow } from "domain/common/iconLegend/IconLegendWindow";
import { RulesWindow } from "domain/businessRules/manage/RulesWindow";

type WindowComponentType = React.ComponentType<any> &
  WindowStaticProps & {
    windowComponentSerializeName?: string;
  };

class SerializeSupportedWindows {
  windowComponentByName: Record<string, React.ComponentType<any>> = {};

  addSupportedWindow(cmp: WindowComponentType, componentName: string) {
    if (!componentName) {
      console.warn(
        "No name for supported serialized window, outdated browser?"
      );
      return this;
    }

    if (this.windowComponentByName[componentName]) {
      console.warn(
        `Double registration of window for serialized state with name: ${componentName}`
      );
      return this;
    }

    if (cmp.windowComponentSerializeName) {
      console.warn(
        `Regstration with name ${componentName} already serialized window with name ${cmp.windowComponentSerializeName}`
      );
      return this;
    }

    this.windowComponentByName[componentName] = cmp;
    cmp.windowComponentSerializeName = componentName;
    return this;
  }

  isSupported(cmp: WindowComponentType) {
    return Boolean(cmp.windowComponentSerializeName);
  }

  getWindowComponentSerializeName(cmp: WindowComponentType) {
    return cmp.windowComponentSerializeName || "";
  }

  getComponentBySerializeName(
    componentName: string
  ): React.ComponentType<any> | undefined {
    return this.windowComponentByName[componentName];
  }
}

export const serializeSupportedWindows = new SerializeSupportedWindows()
  .addSupportedWindow(ItcListWindow, "ItcListWindow")
  .addSupportedWindow(ItcDetailsWindow, "ItcDetailsWindow")
  .addSupportedWindow(MapWindow, "MapWindow")
  .addSupportedWindow(ItcStateStatsWindow, "ItcStateStatsWindow")
  .addSupportedWindow(VehiclePosLogWindow, "VehiclePosLogWindow")
  .addSupportedWindow(IconLegendWindow, "IconLegendWindow")
  .addSupportedWindow(RulesWindow, "BusinessRulesWindow");
