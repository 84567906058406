import { DayOfWeek } from "apinet/models";
import { useEffect, useRef } from "react";

export const monthNames: string[] = [
  "styczeń",
  "luty",
  "marzec",
  "kwiecień",
  "maj",
  "czerwiec",
  "lipiec",
  "sierpień",
  "wrzesień",
  "październik",
  "listopad",
  "grudzień",
];

export const dayOfWeekNames: string[] = [
  "niedziela",
  "poniedziałek",
  "wtorek",
  "środa",
  "czwartek",
  "piątek",
  "sobota",
];
export const dayOfWeekShortNames: string[] = ["nd", "pn", "wt", "śr", "czw", "pt", "so"];
export const daysOfWeekFromMonday: DayOfWeek[] = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

export function currentUnixTime(): number {
  return dateToUnix(new Date());
}

export function getTodayDate(): Date {
  let d = new Date();
  d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
  return d;
}

export function getStartOfCurrentMonth(): Date {
  let d = new Date();
  d = new Date(d.getFullYear(), d.getMonth(), 1);
  return d;
}

export function getTodayUnixTime(): number {
  return dateToUnix(getTodayDate());
}

export function unixToDate(unix: number): Date {
  return new Date(unix * 1000);
}

export function dateToUnix(date: Date): number {
  return Math.floor(date.getTime() / 1000);
}

export function getDayOrHoursTimeLeftDescription(expiryTime: number): string {
  const now = currentUnixTime();
  if (now > expiryTime) {
    return `-`;
  }

  const hours = Math.floor((expiryTime - now) / 3600);
  const days = Math.floor(hours / 24);

  return `${days ? `${days} dni` : `${hours} godzin`}`;
}

export function utcSecondsToDateTime(seconds: number | undefined, format?: string) {
  if (!seconds) {
    return seconds;
  }

  if (seconds && !isNaN(seconds)) {
    const date = new Date(seconds * 1000);
    format = format || "yyyy-MM-dd HH:mm:ss";
    return formatDate(date, format);
  }

  return seconds;
}

export function formatUnixDate(value: number, format: string) {
  return formatDate(unixToDate(value), format);
}

export function formatUnixTimeRelativeToNow(value: number) {
  const todayStr = formatUnixDate(currentUnixTime(), "yyyy-MM-dd");
  const formattedWithDate = formatUnixDate(value, "yyyy-MM-dd HH:mm");
  if (formattedWithDate.startsWith(todayStr)) {
    return formatUnixDate(value, "HH:mm");
  }

  return formattedWithDate;
}

export function formatDate(value: Date | number | string, format: string) {
  const date = new Date(value);
  let result = format.replace(/(yyyy|MM|dd|HH|mm|sss|ss)/g, dateFormatReplacerFactory(date));
  return result;
}

export function getTodayDateString(): string {
  return formatDate(new Date(), "yyyy-MM-dd");
}

const replacerFns: { [key: string]: (x: Date) => string } = {
  yyyy: (x: Date) => x.getFullYear().toString(),
  MM: (x: Date) => lpad(x.getMonth() + 1),
  dd: (x: Date) => lpad(x.getDate()),
  HH: (x: Date) => lpad(x.getHours()),
  mm: (x: Date) => lpad(x.getMinutes()),
  ss: (x: Date) => lpad(x.getSeconds()),
  sss: (x: Date) => lpad(x.getMilliseconds(), 3),
};

function dateFormatReplacerFactory(value: Date) {
  return function dateFormatReplacer(match: string): string {
    const replacerFn = replacerFns[match];
    if (replacerFn) {
      return replacerFn(value);
    } else {
      return match;
    }
  };
}

function lpad(value: number, length = 2): string {
  return value.toString().padStart(length, "0");
}

interface DateFromToAutoCorrectionModel {
  dateFrom?: number;
  dateTo?: number;
}

export function useDateFromToAutoCorrection<T extends DateFromToAutoCorrectionModel>(
  model: T,
  setFn: (value: T) => any
) {
  const { dateFrom, dateTo } = model;
  const modelRef = useRef(model);
  const setFnRef = useRef(setFn);
  modelRef.current = model;
  setFnRef.current = setFn;

  useEffect(() => {
    const x = modelRef.current;
    if (x.dateFrom && x.dateTo && x.dateTo < x.dateFrom) {
      setFnRef.current({
        ...x,
        dateTo: x.dateFrom,
      });
    }
  }, [dateFrom]);

  useEffect(() => {
    const x = modelRef.current;
    if (x.dateFrom && x.dateTo && x.dateTo < x.dateFrom) {
      setFnRef.current({
        ...x,
        dateFrom: x.dateTo,
      });
    }
  }, [dateTo]);
}

export function useGenericDateFromToAutoCorrectionOnString(
  dateFrom: string | undefined,
  dateTo: string | undefined,
  onSetDateStart: (value: string) => any,
  onSetDateEnd: (value: string) => any
) {
  const dateFromRef = useRef(dateFrom);
  dateFromRef.current = dateFrom;
  const dateToRef = useRef(dateTo);
  dateToRef.current = dateTo;

  const onSetDateStartRef = useRef(onSetDateStart);
  onSetDateStartRef.current = onSetDateStart;
  const onSetDateEndRef = useRef(onSetDateEnd);
  onSetDateEndRef.current = onSetDateEnd;

  useEffect(() => {
    if (dateFrom && dateToRef.current) {
      const d1 = new Date(dateFrom);
      const d2 = new Date(dateToRef.current);
      if (d2.getTime() < d1.getTime()) {
        onSetDateEndRef.current(dateFrom);
      }
    }
  }, [dateFrom]);

  useEffect(() => {
    if (dateTo && dateFromRef.current) {
      const d1 = new Date(dateFromRef.current);
      const d2 = new Date(dateTo);
      if (d1.getTime() > d2.getTime()) {
        onSetDateStartRef.current(dateTo);
      }
    }
  }, [dateTo]);
}

export function getWeekRangeContainingDate(d: Date): [Date, Date] {
  const weekStart = new Date(d.getFullYear(), d.getMonth(), d.getDate());
  let day = weekStart.getDay() - 1;
  if (day < 0) day = 6;
  weekStart.setDate(weekStart.getDate() - day);
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekEnd.getDate() + 7);
  return [weekStart, weekEnd];
}
